// Global Styles 
* {
  margin: 0;
  padding: 0;
}

// Fonts
@font-face {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url(../assets/fonts/Roboto-Regular.woff2) format('woff2');
  font-display: swap;
}

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(https://fonts.gstatic.com/s/materialicons/v114/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

body {
  color: $pm-dark-gray;
  background: $pm-body-gray;
  padding-bottom: 40px;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;

  &.menu-open {
    overflow: hidden;
  }
}

.body-box {
  background: #ffffff;
  padding: 20px;
}

.container {
  max-width: 1440px;
}

.container:not(.header-container) {
  margin-top: 20px;
}

.container-full {
  width: 100%;
}

ul {
  margin-left: 15px;
}

// .custom-container {
//   margin-top: 60px;

//   @media screen and (min-width: 768px) and (max-width: 1158px) {
//     margin-top: 120px;
//   }
// }

.row {
  margin-bottom: 25px;
}

// Global Panel Styling
.panel-body {
  display: block;
  text-transform: capitalize;
}

.panel-sub-title {
  font-size: 1em;
}

.panel-actions {
  .btn {
    margin-bottom: 10px;
  }
}

// Switch Styling
.switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 28px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $gray3;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: $white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: $blue;
}

input:focus+.slider {
  box-shadow: 0 0 1px $blue;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.cursor-pointer {
  cursor: pointer;
}

// Accordions
.store-accordion mat-expansion-panel-header,
.store-accordion .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]):hover,
.store-accordion .mat-expansion-panel-header.mat-expanded:hover,
.store-accordion .mat-expansion-panel-header.mat-expanded:focus,
.db-accordion mat-expansion-panel-header,
.db-accordion .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]):hover,
.db-accordion .mat-expansion-panel-header.mat-expanded:hover,
.db-accordion .mat-expansion-panel-header.mat-expanded:focus {
  background: $pm-blue;
  color: $white;
}

.store-accordion mat-expansion-panel-header mat-panel-title,
.store-accordion mat-expansion-panel-header mat-panel-description,
.store-accordion mat-expansion-panel-header .mat-expansion-indicator::after,
.db-accordion mat-expansion-panel-header mat-panel-title,
.db-accordion mat-expansion-panel-header mat-panel-description,
.db-accordion mat-expansion-panel-header .mat-expansion-indicator::after {
  color: $white;
}

.store-accordion mat-expansion-panel-header mat-panel-description {
  font-weight: 200;
  text-transform: lowercase;
}

.fees-accordion mat-expansion-panel-header,
.fees-accordion .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]):hover,
.fees-accordion .mat-expansion-panel-header.mat-expanded:hover,
.fees-accordion .mat-expansion-panel-header.mat-expanded:focus {
  background: $sd-blue;
  color: $white;
}

.fees-accordion mat-expansion-panel-header mat-panel-title,
.fees-accordion mat-expansion-panel-header mat-panel-description,
.fees-accordion mat-expansion-panel-header .mat-expansion-indicator::after {
  color: $white;
}

.warn-accordion mat-expansion-panel-header,
.warn-accordion .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]):hover,
.warn-accordion .mat-expansion-panel-header.mat-expanded:hover,
.warn-accordion .mat-expansion-panel-header.mat-expanded:focus {
  background: $red;
  color: $white;
}

.warn-accordion mat-expansion-panel-header mat-panel-title,
.warn-accordion mat-expansion-panel-header mat-panel-description,
.warn-accordion mat-expansion-panel-header .mat-expansion-indicator::after {
  color: $white;
}

.navbar-fixed-top .navbar-collapse {
  max-height: none;
  height: auto;
}

.card-box .mat-card-header-text {
  margin: 0;
}

// Hiding Scrollbar for Windows
// ::-webkit-scrollbar {
//   width: 0px;
//   background: transparent; __<<ngThemingMigrationEscapedComment0>>__
// }

// Loader
.loader-box {
  position: absolute;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.9);
  z-index: 99;

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  app-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
:host ::ng-deep .ui-editing-cell {
  padding: 0 !important;
}

:host ::ng-deep .ui-toggler-column.ui-editing-cell {
  padding-left: 0.857em !important;
}

// HR Styles
.hr-blue {
  width: 100%;
  border-color: $pm-blue;
  border-width: 3px;
}

// Margin Classes
.mb-0 {
  margin-bottom: 0;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mt-20 {
  margin-top: 20px;
}

.ml-40 {
  margin-left: 40px;
}

.mr-20 {
  margin-right: 20px;
}

.mlr-15 {
  margin: 0 15px;
}

// Inline Classes
.inline-block {
  display: inline-block;
}

// View More or Less
.more-less {
  cursor: pointer;
  color: #02529f;
  font-weight: 700;
  margin-bottom: 20px;
}

// Hidden class
.hidden {
  display: none;
}
.hidden.show {
  display: block;
}

// Material 
.mat-tab-group.mat-background-primary .mat-tab-header {
  background: #757575;
  box-shadow: 0px 4px 5px rgb(0 0 0 / 14%), 0px 1px 10px rgb(0 0 0 / 12%), 0px 2px 4px rgb(0 0 0 / 20%);
  z-index: 99;
}

.mat-tab-label-container {
  width: 1440px;
  margin: 0 auto;
  flex-grow: initial !important;
}

.store-tabs .mat-tab-body {
  background: #e5e5e5;
}

.mat-tab-label.mat-tab-label-active {
  opacity: 1;
  // background: #90abd0;
}

.mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: #fff;
}

// Material Card
.mat-card:not(.home-card) mat-card-title.mat-card-title {
    color: #2b2b2b;
}

// Mat Card
mat-card-title.mat-card-title {
  font-size: 18px;
}

// Material Error
mat-error {
  font-size: 12px;
}

.default-background-color {
  background: $light-background;
}

.margin-left-1{
  margin-left: 1em !important;
}

.mat-input-element:disabled {
  color: #8c8c8c;
}

// Flex Grid
.flex-grid {
  display: flex;
}

.flex-grid .flex-col {
  flex: 1;
}

.flex-grid-half {
  display: flex;
  justify-content: space-between;
}

.flex-grid-half .flex-col {
  width: 50%;
  padding: 0 5px;
}

.flex-grid-thirds {
  display: flex;
  justify-content: space-between;
}

.flex-grid-thirds .flex-col {
  width: 33.33%;
  padding: 0 5px;
}

.flex-vert-center {
  align-items: center;
  display: flex;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-horizontal-right {
  justify-content: right;
}

.flex-horizontal-left {
  justify-content: left;
}

// Mat Tabs
.mat-tab-group.mat-background-primary .mat-tab-header-pagination {
  background: #757575;
}

// Clear
.clear {
  clear: both;
}

// Positioning
.vert-center  {
  vertical-align: middle;
}

@media screen and (max-width: 1215px) {
  .flex-grid,
  .flex-grid-thirds {
    flex-wrap: wrap;
    .flex-col {
      width: 50%;
    }
  }
}

// Safari only media query
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) {
  body {
    padding-bottom: 0;
  }
}}