// Snackbar
.success-dialog {
  background: $pm-blue !important;
  color: $white !important;

  .mat-simple-snackbar-action {
    color: $gray2;
  }
}

.success-green-dialog {
  background: $green !important;
  color: $white !important;

  .mat-simple-snackbar-action {
    color: $gray2;
  }
}

.error-dialog {
  background: $red !important;
  color: $white !important;

  .mat-simple-snackbar-action {
    color: $gray2;
  }
}

// Material Dialog
.mat-dialog-container {
  max-height: 500px;
  overflow-y: scroll;
}

//master-slot-dialog-container
@media screen and (max-width: 767px) {
  .master-slot-dialog-container {
      overflow-y: auto !important;
  }
}