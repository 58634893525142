// Store Fee button
.mat-fab.mat-primary {
  background-color: $light-blue;
}

// Primary Flat Button
button.mat-primary.mat-button:not(.mat-button) {
  background-color: $pm-blue;
}

// Button Base
.action-btn-theme button.mat-primary:not(.mat-fab):not(.mat-mini-fab),
.action-btn-theme button.mat-accent:not(.mat-fab):not(.mat-mini-fab),
.action-btn-theme button.mat-warn:not(.mat-fab):not(.mat-mini-fab),
.action-btn-theme a.mat-primary:not(.mat-fab):not(.mat-mini-fab),
.action-btn-theme a.mat-accent:not(.mat-fab):not(.mat-mini-fab),
.action-btn-theme a.mat-warn:not(.mat-fab):not(.mat-mini-fab),
button.mat-primary:not(.mat-fab):not(.mat-mini-fab):not(.mat-button),
button.mat-accent:not(.mat-fab):not(.mat-mini-fab):not(.mat-button),
button.mat-warn:not(.mat-fab):not(.mat-mini-fab):not(.mat-button),
a.mat-primary:not(.mat-fab):not(.mat-mini-fab),
a.mat-accent:not(.mat-fab):not(.mat-mini-fab),
a.mat-warn:not(.mat-fab):not(.mat-mini-fab) {
  color: $white;
  font-weight: 700 !important;
  font-size: 16px !important;
  min-height: 40px;
  max-width: 170px;
  white-space: normal !important;
  line-height: 18px !important;
  padding: 10px 5px !important;
  width: 100%;
}

.action-btn-theme button.mat-primary:not(.mat-fab):not(.mat-mini-fab)[color="accent"],
.action-btn-theme button.mat-accent:not(.mat-fab):not(.mat-mini-fab)[color="accent"],
.action-btn-theme button.mat-warn:not(.mat-fab):not(.mat-mini-fab)[color="accent"],
.action-btn-theme a.mat-primary:not(.mat-fab):not(.mat-mini-fab),
.action-btn-theme a.mat-accent:not(.mat-fab):not(.mat-mini-fab),
.action-btn-theme a.mat-warn:not(.mat-fab):not(.mat-mini-fab) {
  color: $pm-blue;
}

.action-btn-theme a.mat-primary:not(.mat-fab):not(.mat-mini-fab),
.action-btn-theme a.mat-accent:not(.mat-fab):not(.mat-mini-fab),
.action-btn-theme a.mat-warn:not(.mat-fab):not(.mat-mini-fab) {
  &:hover {
    text-decoration: none;
  }
}

// Secondary Button
button.mat-accent {
  background-color: $sd-blue;
}

button.mat-focus-indicator.mat-fab.mat-button-base.mat-primary {
  border-radius: 50%;
  height: auto;
}

// Success Button
button[color="success"].mat-accent {
  background-color: #00a250;
}

// Disabled Buttons
.mat-raised-button[disabled]:not([class*=mat-elevation-z]),
.mat-flat-button[disabled]:not([class*=mat-elevation-z]) {
  background: #969696 !important;
  cursor: not-allowed;
}
button.mat-button.mat-button-disabled {
  background-color: #969696;
  cursor: not-allowed;
}

// Remove button
button.removeBtn.mat-warn:not(.mat-fab):not(.mat-mini-fab) {
  background-color: #fff;
  color: #DB202D;
  border: 1px solid #000;
  letter-spacing: 1.25px;
}

// Prime NG Buttons
::ng-deep .p-button,
::ng-deep button.p-autocomplete-dropdown {
  background: #00529F;
}