// Primary Colors
$pm-blue: #00529F;
$pm-gray: #f7f7f7;
$pm-link: #3787bc;
$pm-dark-gray: #4b4b4b;
$pm-green: #4ec78d;
$pm-gray-header: #afafaf;
$pm-gray-txt: #979797;

// - Background Colors
$pm-bg-blue: #c4d7e5;
$pm-gray-bg: #b9b9b9;
$pm-gray-header-bg: #f6f6f6;
$pm-body-gray: #e5e5e5;

// Secondary Colors
$sd-blue: #90abd0;

// Color Variables (Alphabetical)
$black: #000000;
$blue: #0075c9;
$blue-gray: #778da9;
$blue-gray2: #415A77;
$dark-blue: #1b263b;
$gray: #b3b3b3;
$gray2: #dadada;
$gray3: #cccccc;
$green: #0C7D50;
$light-blue: #3e92cc;
$light-gray: #efefef;
$orange: #91520C;
$red: #C63939;
$yellow: #fad05e;
$white: #ffffff;
$light-tan: #FEF9E5;

// Homepage colors
$home-1: #355070;
$home-2: #6d597a;
$home-3: #b56576;
$home-4: #e56b6f;
$home-5: #eaac8b;
$home-6: #2a295e;
$home-7: #0c7d50;


.pm-bg-blue,
.mat-card.pm-bg-blue {
    color: #ffffff;
    background-color: $pm-blue;

    mat-card-subtitle.mat-card-subtitle {
        color: #efefef;
    }
}

.pm-bg-accent,
.mat-card.pm-bg-accent {
    background-color: $pm-bg-blue;
    color: #000000;

    mat-card-subtitle.mat-card-subtitle {
        color: #4d4d4d;
    }
}

.sd-bg-blue,
.mat-card.sd-bg-blue {
    color: #ffffff;
    background-color: $sd-blue;

    mat-card-subtitle.mat-card-subtitle {
        color: #efefef;
    }
}

.home-bg1,
.mat-card.home-bg1 {
    color: #ffffff;
    background-color: $home-1;

    mat-card-subtitle.mat-card-subtitle {
        color: #efefef;
    }
}

.home-bg2,
.mat-card.home-bg2 {
    color: #ffffff;
    background-color: $home-2;

    mat-card-subtitle.mat-card-subtitle {
        color: #efefef;
    }
}

.home-bg3,
.mat-card.home-bg3 {
    color: #ffffff;
    background-color: $home-3;

    mat-card-subtitle.mat-card-subtitle {
        color: #efefef;
    }
}

.home-bg4,
.mat-card.home-bg4 {
    color: #ffffff;
    background-color: $home-4;

    mat-card-subtitle.mat-card-subtitle {
        color: #efefef;
    }
}

.home-bg5,
.mat-card.home-bg5 {
    color: #ffffff;
    background-color: $home-5;

    mat-card-subtitle.mat-card-subtitle {
        color: #efefef;
    }
}

.home-bg6,
.mat-card.home-bg6 {
    color: #ffffff;
    background-color: $home-6;

    mat-card-subtitle.mat-card-subtitle {
        color: #efefef;
    }
}

.home-bg7,
.mat-card.home-bg7 {
    color: #ffffff;
    background-color: $home-7;

    mat-card-subtitle.mat-card-subtitle {
        color: #efefef;
    }
}

.home-background {
    background: #08C6FF;
    background: -webkit-linear-gradient(top right, #08C6FF, #01EE89);
    background: -moz-linear-gradient(top right, #08C6FF, #01EE89);
    background: linear-gradient(to bottom left, #08C6FF, #01EE89);
}
