// Title Texts
h1, h2, h3, h4, h5, strong {
  color: #2b2b2b;
}

// Text Color
.grey-txt {
  color: $pm-dark-gray;
}

.blue-txt {
  color: $pm-blue;
}

.red-txt {
  color: $red;
}

.green-txt {
  color: $green;
}

.orange-txt {
  color: $orange;
}

.yellow-txt {
  color: $yellow;
}

.grey-pm-txt {
  color: $pm-gray-txt;
}

// Text Margins
.title {
  margin-bottom: 1em;
}

// Text Sizes
.small-txt {
  font-size: 12px;
}

// Error
.error-txt {
  font-size: 12px;
  color: #e74c3c;
  padding: 0;
  margin: 0;
  text-align: left;
}

// Uppercase
.text-upper {
  text-transform: uppercase;
}

// Font weight
.fw-bold {
  font-weight: bold;
}

// Home Title Texts
.home-txt1 {
  color: $home-1 !important;
}

.home-txt2 {
  color: $home-2 !important;
}

.home-txt3 {
  color: $home-3 !important;
}

.home-txt4 {
  color: $home-4 !important;
}

.home-txt5 {
  color: $home-5 !important;
}

.home-txt6 {
  color: $home-6 !important;
}

.home-txt7 {
  color: $home-7 !important;
}

.txt-14 {
  font-size: 14px;
}