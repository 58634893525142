// Global Form Styling
.form-group.timeGrp {
  display: inline-block;
  padding-right: 25px;
  vertical-align: top;
}

// Mat Chips
.mat-chip.mat-standard-chip.mat-primary {
  background-color: $blue;
  color: $white;
}

.mat-chip.mat-standard-chip.mat-warn {
  background-color: $red;
  color: $white;
}


.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: $sd-blue;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-label {
  color: $sd-blue;
}

// custom slider color, wile changing please ask co-developer for any dependency
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label,
.mat-accent .mat-slider-track-fill {
  background-color: $white; //Your color
}

// custom material color for radio
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: $pm-blue;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: $sd-blue;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


// Form Field
.mat-form-field {
    @media screen and (max-width: 450px) {
        width: 100%;
    }
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  color: $black;
  font-weight: 700;
}

// Select Styling 
select,
select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  background: $white;
  background-image: none;
  cursor: pointer;
  padding-right: 30px;
}

.select-wrapper {
  position: relative;
  display: table;
}

.select-wrapper:not(.auto-width) {
  width: 100%;
}

.select-wrapper::after {
  content: "\f078";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  color: $black;
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 7px;
  pointer-events: none;
}
.select-auto {
  width: auto;
}

// Checkbox
.mat-checkbox-checked.mat-primary .mat-checkbox-background,
.mat-primary .mat-pseudo-checkbox-checked {
    background-color: $pm-dark-gray;
}

// Material Tabs
.mat-tab-body {
    padding: 25px 20px;
    background-color: #ffffff;
}
